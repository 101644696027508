<template>
  <div class="auth-content">
    <div class="auth window-width column justify-center items-center">
      <q-card class="default-rounded q-ma-md">
        <q-card-section class="text-center">
          <q-img width="140px" :src="logo" />
        </q-card-section>
        <router-view></router-view>
      </q-card>

      <q-card class="footer-card">
        <FooterComponent />
      </q-card>
    </div>
  </div>
</template>
<script>
export default {
  name: "Auth",
};
</script>
<script setup>
  import { useGetters } from "vuex-composition-helpers";
  import { useRoute } from "vue-router";
  import { useQuasar } from "quasar";
  import { onMounted } from 'vue'
 
  import FooterComponent from "../../main/components/FooterComponent.vue";
  
  const route = useRoute();
  const $q = useQuasar();

  const { partner, logo } = useGetters({
    partner: "partner/getPartner",
    logo: "partner/getPartnerParentLogo",
  });

  onMounted(() => {
    if (route.query.message) {
      $q.notify({
          progress: true,
          position: "top",
          timeout: 10000,
          avatar: partner.logo_foto || partner.logo,
          type: "info",
          message: route.query.message,
      });
    }
  })
</script>
<style lang="scss" scoped>
.auth {
  width: 100%;
  height: 100%;
}

.q-card:last-child {
  background: transparent;
  box-shadow: none !important;
}

.desktop {
  .auth {
    background: #f0f0f0;
    min-height: 100vh;

    .q-card {
      width: 100%;
      max-width: 480px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 8%);
    }
  }
}
.mobile {
  .auth-content {
    height: 100vh;
  }

  .auth {
    align-items: normal;
    background: #fff;
    display: grid;
    max-height: 100vh;

    .q-card {
      width: 100vw;
      box-shadow: none;
      margin: 0;
    }
    
    .footer-card {
      height: fit-content;
      align-self: baseline;
    }
  }
}

:root {
  --q-negative: #f16192;
}

html {
  overflow-x: hidden;
}

.footer :deep(.q-item__label){
  display: none;
}

.footer {
  margin-right: 0px !important;
  margin-left: 0px !important;
  margin-top: 0px;
}
</style>
<template>
  <div class="q-ml-lg footer q-mr-lg">
    <q-item>
      <q-item-section>
        <q-item-label class="text-grey-8">
          {{ $t("global.footer.version", { value: "2.0.1" }) }}
        </q-item-label>
      </q-item-section>

      <q-item-section side class="item">
        {{ $t("global.footer.powered_by") }}
      </q-item-section>
      <q-item-section side @click="goToPage">
        <q-avatar rounded size="60px">
          <img src="/flip/flip-icon.png" />
        </q-avatar>
      </q-item-section>
    </q-item>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",

  methods: {
    goToPage() {
      const utmMedium = this.pageName()

      window.open(
        `https://flip.net.br/?utm_source=flipapp&utm_medium=${utmMedium}page&utm_campaign=logoflip`, 
        '_blank'
      )
    },
    pageName() {
      const currentRoute = this.$route.name;

      let utmMedium = currentRoute;

      if ( currentRoute !== 'recover' && currentRoute !== 'login' ) {
        utmMedium = currentRoute == 'sign.up' ? 'signup' : 'dashboard'
      }

      return utmMedium
    }
  }
};
</script>

<style scoped>
.footer {
  margin-top: 50px;
}

:deep(.q-item__section:last-child) {
  cursor: pointer;
}
</style>
